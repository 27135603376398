<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="text-center">
          {{ $t("messages.newsletterUnsubscription") }}
        </h1>
        <h4 class="text-center mt-4">
          {{ $t("messages.newsletterUnsubscriptionSuccess") }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import NewsletterService from "@/services/newsletter.service.js";

export default {
  name: "UnsuscribeNewsletterMail",
  data() {
    return {
      newsletterService: null,
    };
  },
  methods: {},
  created() {
    this.newsletterService = new NewsletterService();
  },
  mounted() {
    //recovery id from url
    let id = this.$route.params.id;
    if (!id) return;
    //call to api
    try {
      this.newsletterService.unsuscribeMailEncoded(id);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
.container {
  padding: 200px 0px;
}

@media (max-width: 1400px) {
  .container {
    padding: 150px 0px;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 130px 0px;
  }
}
</style>
